#root {
  --fixed-header-height: 60px;
  --filter-bar-height: 40px;
}

body {
  background: #f5f5f5;
}

.app {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu-frame {
  display: flex;
  flex-direction: row;
  height: 59px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.workspace-frame {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 59px;
  max-width: 100%;
}

.activity-bar.compressed + .workspace-frame {
  padding-left: 80px;
}

.activity-bar.expanded + .workspace-frame {
  padding-left: 216px;
}

.wgl-gradient-background {
  background: linear-gradient(to bottom to right, #2278ae, #0f3a55) !important;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-who {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.ui[class*="very padded"].segment {
  padding: 2.5rem 2rem;
}

.ui.table th {
  /* this override should be simple enough theme style when ready? */
  text-transform: uppercase !important;
  font-size: 0.8rem;
}

.ui.table tbody td .ui.buttons,
.ui.table tbody td .ui.button {
  border: 0;
}

.ui.table tbody td .ui.buttons,
.ui.table tbody td .ui.button:hover {
  border: 0;
  /* not sure why default semantic has !important */
  background: none !important;
  color: black !important;
  box-shadow: none !important;
}

.editor-canvas-views,
.settings-canvas-views,
.edit-field-details-content {
  --secondary-bar-bg: #f0f2f5;
  --secondary-bar-accent: #e1e4eb;
  --secondary-bar-color: #48546b;
  --secondary-bar-color-active: #48546b;
}

.editor-canvas-views .ui.secondary.pointing.menu,
.settings-canvas-views .ui.secondary.pointing.menu,
.edit-field-details-content .ui.secondary.pointing.menu {
  background: var(--secondary-bar-bg);
}

.editor-canvas-views .ui.secondary.pointing.menu .item,
.settings-canvas-views .ui.secondary.pointing.menu .item,
.edit-field-details-content .ui.secondary.pointing.menu .item {
  padding-left: 2em !important;
  padding-right: 2em !important;
  color: var(--secondary-bar-color);
}
.modals .editor-canvas-views .ui.secondary.pointing.menu .item,
.modals .settings-canvas-views .ui.secondary.pointing.menu .item,
.modals .edit-field-details-content .ui.secondary.pointing.menu .item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: auto;
  flex: 1;
}
.editor-canvas-views .ui.secondary.pointing.menu .item:hover,
.settings-canvas-views .ui.secondary.pointing.menu .item:hover,
.edit-field-details-content .ui.secondary.pointing.menu .item:hover {
  background: var(--secondary-bar-accent);
  border-color: var(--secondary-bar-color-active);
}
.editor-canvas-views .ui.secondary.pointing.menu .active.item,
.editor-canvas-views .ui.secondary.pointing.menu .active.item:hover,
.settings-canvas-views .ui.secondary.pointing.menu .active.item,
.settings-canvas-views .ui.secondary.pointing.menu .active.item:hover,
.edit-field-details-content .ui.secondary.pointing.menu .active.item,
.edit-field-details-content .ui.secondary.pointing.menu .active.item:hover {
  background: var(--secondary-bar-accent);
  border-color: var(--secondary-bar-color-active);
  color: var(--secondary-bar-color-active);
}

.ui.segments,
.ui.segment {
  border-radius: 2px;
}
.ui.segment {
  padding: 2rem;
}

.ui.segment.bottom.attached {
  padding: 1.2rem 2rem;
}

.ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.025) !important;
}

.ui.form .fields > .fieldItem-fit {
  flex: 1;
}

.ui.form .wide.field .ui.input input[type="text"] {
  /* don't let text inputs collapse to less than 80px*/
  min-width: 80px;
}

/* card action buttons */
.search-action-section .ui.buttons {
  border: 0;
}

.search-action-section .ui.buttons .ui.button {
  border: 0;
  padding: 0.78571429em 0.48571429em 0.78571429em;
}

.search-action-section .ui.buttons .ui.button:hover {
  box-shadow: none;
  background: #f6f6f6 !important;
  border-radius: 5px;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 30px;
}

.search-results .ui.cards > .card,
.search-results .ui.card {
  width: 100%;
  max-width: 300px;
  margin: 0 0 4px 0; /* allows box-shadow bottom to show */
  position: relative;
}

.search-results .ui.cards > .card > .content.card-shrink,
.search-results .ui.card > .content.card-shrink {
  flex: 0;
}

.search-results .ui.cards > .card > .content > .header,
.search-results .ui.card > .content > .header {
  display: flex;
  justify-content: space-between;
}

.search-results .ui.cards .card-main,
.search-results .ui.card .card-main {
  border-top: 0;
  padding-top: 0;
}

.search-results .ui.cards .card-main > div,
.search-results .ui.card .card-main > div {
  display: flex;
  flex-direction: column;
}

.search-results .ui.cards .card-preview,
.search-results .ui.card .card-preview {
  background: #f9f9f9;
  padding: 1rem;
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-radius: 4px;
  flex: 1;
}

.search-results .ui.card .card-footer {
  margin: 0em 1em 0.5em 1em;
  padding: 0.75em 0;
  display: flex;
  border: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.search-results .ui.card .card-footer-left {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.1;
}

.search-results .ui.card .card-footer-left .avatar {
  margin-right: 0.75rem;
}

.search-results .ui.card .card-footer-left .card-last-edited {
  font-size: 10px;
  opacity: 0.5;
}

.search-results .ui.card .card-footer-right {
  margin-left: auto;
  text-align: right;
}

.search-results .ui.card .card-footer-right .avatar {
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: all 0.25s ease-in-out;
}

.search-results .ui.card .card-footer-right .avatar:hover {
  transform: translate3d(0, -5px, 0);
}

.search-results .ui.card .card-footer .avatar {
  border: 1px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 2rem;
  height: 2rem;
}

.search-results .ui.card .card-footer .avatar + .avatar {
  margin-left: -15%;
}

.search-results .ui.cards .card-description,
.search-results .ui.card .card-description {
  opacity: 0;
  overflow: hidden;
  padding: 0;
  max-height: 0;
  position: relative;
  transform: translate3d(0, 10px, 0);
  transition: all 0.25s ease-in-out, transform 0.5s ease-in-out 0.15s,
    opacity 0.5s ease-in-out 0.15s;
}

.search-results .ui.card.hovering .card-description {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  max-height: 100px;
  padding: 1rem 0;
  margin-bottom: 0em;
}

.search-results .ui.card .card-description:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 0%;
  height: 1px;
  background: rgba(34, 36, 38, 0.1);
  transition: width 0.75s ease-in-out 0.5s;
}

.search-results .ui.card.hovering .card-description:after {
  width: 100%;
}

.search-results .ui.card .card-header .dropdown {
  margin-left: auto;
}

.search-results .ui.card .card-header .dropdown > .icon {
  font-size: 1rem;
  text-align: right;
}

.search-results .ui.card .card-header .dropdown .menu {
  left: initial;
  right: 0;
}

.ui.card.hovering {
  cursor: pointer;
}

.ui.card .card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ui.card .card-img.no-avatar:before {
  content: attr(data-initials);
  position: absolute;
  width: 100%;
  z-index: 100;
  color: rgba(0, 0, 0, 0.6);
  font-size: 36px;
  line-height: 1.2;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
}

.ui.popup.inverted {
  border: 1px solid #092542;
  background: #0d334b;
}
.ui.popup.inverted:before {
  background: #0d334b !important;
}

/* toggle contrast improvements */
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background: #f5f5f5;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after,
.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  background: #fff;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  box-shadow: inset 0 0 0 1px #ccc;
}

.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  box-shadow: inset 0 0 0 1px #ccc, -2px 0px 2px rgba(0, 0, 0, 0.2);
}

/* utility classes */
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-results.identity-cards {
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}

.ui.card.identity-card {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  max-width: none;
}

.ui.card.identity-card .meta a {
  font-size: 0.9em;
  color: #555;
}

.ui.card.identity-card .card-img {
  width: 100px;
  min-width: 100px;
  height: auto;
  border-radius: 100px !important;
  overflow: hidden;
  margin: 1rem;
  box-sizing: border-box;
  position: relative;
}

.ui.card.identity-card .card-img:not(.no-avatar) {
  background: none !important;
}

.ui.card.identity-card .content {
  padding: 1em 1em 1em 0;
  border: 0;
}


.react-datepicker-wrapper{
  width: 100%;
}