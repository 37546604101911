.preview-view-editor.schema-builder .preview-list-frame {
  background-color: #fff;
  margin: 0 auto;
}

.preview-view-editor.schema-builder .ui.form {
  max-width: 1280px;
  min-width: 960px;
}

.preview-view-editor.schema-builder .fields,
.preview-view-editor.schema-builder .field {
  padding: 0.1rem;
  width: auto;
}

.preview-view-editor.schema-builder .field .ui.input > label {
}

.preview-view-editor.schema-builder .field .ui.input > input {
}
