.preview-view-editor.wgl-csr .preview-list-frame {
  background-color: #fff;
  margin: 0 auto;
  padding: 0;
}

.preview-view-editor.wgl-csr .ui.form {
  max-width: 1280px;
  min-width: 600px;
}

.preview-view-editor.wgl-csr .ui.segment {
  padding: 0;
}

.preview-view-editor.wgl-csr .fields,
.preview-view-editor.wgl-csr .field {
  padding: 0 1rem;
  width: auto;
}

.preview-view-editor.wgl-csr .ui.segment > .ui.header {
  background-color: #f6faff;
  margin: 0;
  padding: 1.5rem;
  font-size: 1.5rem;
  color: #253e6a;
  font-weight: 400;
  border-bottom: 1px #cbcbcb solid;
  margin-bottom: 2rem;
}
