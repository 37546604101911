.preview-view-editor.looker-app .preview-list-frame {
  background-color: #fff;
  margin: 0 auto;
}

.preview-view-editor.looker-app .ui.form {
  max-width: 600px;
  min-width: 400px;
}

.preview-view-editor.looker-app .ui.segment {
  padding: 0;
}

.preview-view-editor.looker-app .fields,
.preview-view-editor.looker-app .field {
  padding: 0.5rem 1rem;
  width: auto;
}

.preview-view-editor.looker-app .ui.segment > .ui.header {
  margin: 0;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
}
