.preview-view-editor.client-ordering .preview-list-frame {
  background-color: #fff;
  margin: 0 auto;
}

.preview-view-editor.client-ordering .ui.form {
  max-width: 1280px;
  min-width: 800px;
}

.preview-view-editor.client-ordering .fields,
.preview-view-editor.client-ordering .field {
  padding: 1rem;
  width: auto;
  background: #f1f1f1;
}

.preview-view-editor.client-ordering .field .ui.input > label {
}

.preview-view-editor.client-ordering .field .ui.input > input {
  border-radius: 0;
}
